import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Info, Search, Eye, UserPlus, Menu, X, DollarSign, ChevronRight, Users, Settings } from 'lucide-react';

const pulseAnimation = keyframes`
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.05); }
`;

const Container = styled.div`
  min-height: 100vh;
  background: linear-gradient(135deg, #1a0f2e 0%, #2d1b4e 50%, #1a0f2e 100%);
  color: white;
  padding: 1rem;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
  position: relative;
`;

const ClubAvatar = styled.div`
  width: 80px;
  height: 80px;
  background: #2a2a2a;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  border: 2px solid rgba(159, 112, 255, 0.3);
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .host-badge {
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(135deg, #FFD700, #FFA500);
    color: black;
    padding: 2px 6px;
    font-size: 0.75rem;
    font-weight: bold;
  }
`;

const ClubInfo = styled.div`
  flex: 1;
  
  h1 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.25rem;
    background: linear-gradient(to right, #ffffff, #9f70ff, #ffffff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  
  .club-id {
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.9rem;
  }
  
  .welcome-text {
    color: rgba(255, 255, 255, 0.6);
    margin-top: 0.5rem;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 1rem;
  
  button {
    background: rgba(159, 112, 255, 0.1);
    border: none;
    color: #9f70ff;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 50%;
    transition: all 0.3s ease;
    
    &:hover {
      background: rgba(159, 112, 255, 0.2);
      transform: translateY(-2px);
    }
  }
`;

const RakebackSection = styled.div`
  background: linear-gradient(135deg, rgba(41, 31, 71, 0.9) 0%, rgba(59, 47, 99, 0.9) 100%);
  border-radius: 16px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  border: 1px solid rgba(159, 112, 255, 0.2);
  backdrop-filter: blur(10px);
   .button-container {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    
    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      gap: 2rem;
    }
  }
`;

const RakebackHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  
  h2 {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.25rem;
    color: rgba(255, 255, 255, 0.9);
  }
  
  .percentage {
    font-size: 2rem;
    font-weight: bold;
    background: linear-gradient(to right, #9f70ff, #5d3fe8);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
`;

const ProgressContainer = styled.div`
  margin-top: 2rem;
`;



const PlayersProgress = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  
  span {
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
`;

const InviteButton = styled.button`
  width: 100%;
  background: linear-gradient(135deg, #9f70ff 0%, #5d3fe8 100%);
  color: white;
  border: none;
  border-radius: 24px;
  padding: 1rem;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
  margin-top: 2rem;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(159, 112, 255, 0.3);
  }
`;

const MenuToggle = styled.button`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background: linear-gradient(135deg, #9f70ff 0%, #5d3fe8 100%);
  border: none;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 100;
  
  &:hover {
    transform: scale(1.1);
  }
`;

const PlayerMilestones = styled.div`
  display: flex;
  flex:1;
  justify-content: space-between;
align-items:center;
//   margin-bottom: 0.5rem;
  padding: 0 2px;
  margin-left:10px;
  
  span {
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.9rem;
    
    svg {
      margin-left: 4px;
    }
  }
`;

const ProgressBar = styled.div`
  height: 8px;
  background: rgba(159, 112, 255, 0.1);
  border-radius: 4px;
  position: relative;
  margin: 1rem 0;
  
  .progress {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: linear-gradient(to right, #9f70ff, #5d3fe8);
    border-radius: 4px;
    width: ${props => props.progress}%;
    transition: width 0.3s ease;
  }
`;

const ProgressLabels = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  
  span {
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.6);
  }
`;




const MenuToggleButtons = styled.button`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background: linear-gradient(135deg, #9f70ff 0%, #5d3fe8 100%);
  border: none;
  border-radius: 24px;
  width: 150px;
  height: 48px;
  display: flex;
  gap:12px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 100;
`;

const BottomNav = styled.div`
  position: fixed;
  bottom: ${props => props.isOpen ? '0' : '-100%'};
  left: 0;
  right: 0;
  background: linear-gradient(135deg, rgba(41, 31, 71, 0.95) 0%, rgba(59, 47, 99, 0.95) 100%);
  padding: 1rem;
  display: flex;
  justify-content: space-around;
  backdrop-filter: blur(10px);
  transition: bottom 0.3s ease;
  border-top: 1px solid rgba(159, 112, 255, 0.2);
`;

const NavButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.active ? '#9f70ff' : 'rgba(255, 255, 255, 0.6)'};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.8rem;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    color: #9f70ff;
    transform: translateY(-2px);
  }
`;

const CreateOwnClub = () => {
    const [players, setPlayers] = useState(2);
    const [currentRakeback, setCurrentRakeback] = useState(20);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const getProgressPercentage = (players) => {
        if (players <= 2) return 20;
        if (players <= 5) return 40;
        if (players <= 10) return 55;
        if (players <= 20) return 70;
        return 70;
    };

    return (
        <Container>
            <Header>
                <ClubAvatar>
                    <div className="host-badge">Host</div>
                    <img src="https://img.freepik.com/premium-vector/bearded-king-with-crown-his-head-logo-royal-king-symbol_939711-964.jpg" alt="Club avatar" />
                </ClubAvatar>
                <ClubInfo>
                    <h1>Club_hnitzxljou</h1>
                    <div className="club-id">Club ID: 393519</div>
                    <div className="welcome-text">Welcome to the Club!</div>
                </ClubInfo>
                <ActionButtons>
                    <button><Info size={20} /></button>
                    <button><Search size={20} /></button>
                </ActionButtons>
            </Header>

            <RakebackSection>
                <RakebackHeader>
                    <h2>
                        Club Rakeback
                        <Eye size={20} />
                    </h2>
                    <div className="percentage">{currentRakeback}%</div>
                </RakebackHeader>

                <ProgressContainer>
                    <PlayersProgress>
                        <span>Players <UserPlus size={16} /></span>
                        {/* <span>{players}</span> */}
                        <PlayerMilestones>
                            <span>2<Users size={14} /></span>
                            <span>5<Users size={14} /></span>
                            <span>10<Users size={14} /></span>
                            <span>20<Users size={14} /></span>
                        </PlayerMilestones>
                    </PlayersProgress>



                    <ProgressBar progress={getProgressPercentage(players)}>
                        <div className="progress" />
                    </ProgressBar>

                    <ProgressLabels>
                        <span>0%</span>
                        <span>20%</span>
                        <span>40%</span>
                        <span>55%</span>
                        <span>70%</span>
                    </ProgressLabels>
                </ProgressContainer>

                <div className="button-container">
                    <InviteButton>
                        Invite players
                        <ChevronRight size={20} />
                    </InviteButton>
                    <InviteButton>
                        Create Club Agents
                        <ChevronRight size={20} />
                    </InviteButton>
                </div>
            </RakebackSection>

            {
                !isMenuOpen ? (<MenuToggle onClick={() => setIsMenuOpen(!isMenuOpen)}>
                    {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
                </MenuToggle>) : (
                    <MenuToggleButtons onClick={() => setIsMenuOpen(!isMenuOpen)}>
                        <Settings style={{ flex: 1 }} size={22} />
                        <Users style={{ flex: 1 }} size={22} />
                        <Settings style={{ flex: 1 }} size={22} />
                    </MenuToggleButtons>
                )
            }



        </Container>
    );
};

export default CreateOwnClub;