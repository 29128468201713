import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Crown, Trophy, Star, Users, ChevronRight, Award, Gift, Shield, Clock, Globe } from 'lucide-react';
import JoinClubModal from './JoinClubModal';
import { useHistory } from 'react-router-dom';

// Animations remain the same
const float = keyframes`
  0%, 100% { transform: translateY(0) rotate(0deg); }
  50% { transform: translateY(-20px) rotate(5deg); }
`;

const shine = keyframes`
  0% { background-position: 200% center; }
  100% { background-position: -200% center; }
`;

const pulse = keyframes`
  0%, 100% { transform: scale(1); opacity: 0.8; }
  50% { transform: scale(1.05); opacity: 1; }
`;

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

// Updated Styled Components with responsive design
const Container = styled.div`
  min-height: 50vh;
  background: linear-gradient(
    135deg,
    #1a0f2e 0%,
    #2d1b4e 25%,
    #1f1f3f 50%,
    #2d1b4e 75%,
    #1a0f2e 100%
  );
  background-size: 400% 400%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const GlowOrb = styled.div`
  position: absolute;
  border-radius: 50%;
  filter: blur(80px);
  opacity: 0.4;
  animation: ${float} 20s infinite;

  @media (max-width: 768px) {
    filter: blur(60px);
  }
`;

const TopOrb = styled(GlowOrb)`
  width: 400px;
  height: 400px;
  background: radial-gradient(circle at center, #9f70ff, transparent 70%);
  top: -200px;
  left: -200px;

  @media (max-width: 768px) {
    width: 300px;
    height: 300px;
    top: -150px;
    left: -150px;
  }
`;

const BottomOrb = styled(GlowOrb)`
  width: 400px;
  height: 400px;
  background: radial-gradient(circle at center, #5d3fe8, transparent 70%);
  bottom: -200px;
  right: -200px;

  @media (max-width: 768px) {
    width: 300px;
    height: 300px;
    bottom: -150px;
    right: -150px;
  }
`;

const Card = styled.div`
  width: 100%;
  max-width: 800px;
  background: linear-gradient(
    135deg,
    rgba(41, 31, 71, 0.9) 0%,
    rgba(59, 47, 99, 0.9) 50%,
    rgba(41, 31, 71, 0.9) 100%
  );
  border-radius: 24px;
  padding: 3rem;
  position: relative;
  overflow: hidden;
  box-shadow: 
    0 8px 32px rgba(0, 0, 0, 0.3),
    0 0 0 1px rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  
  @media (max-width: 768px) {
    padding: 2rem 1.5rem;
  }

  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: linear-gradient(45deg, #9f70ff, #5d3fe8, #9f70ff);
    z-index: -1;
    border-radius: 24px;
    opacity: 0.3;
    animation: ${shine} 3s linear infinite;
  }
`;

const IconContainer = styled.div`
  position: relative;
  width: 120px;
  height: 120px;
  margin: 0 auto 2rem;

  @media (max-width: 768px) {
    width: 100px;
    height: 100px;
  }
`;
const FloatingIcon = styled.div`
  position: absolute;
  animation: ${float} 3s ease-in-out infinite;
  
  &:nth-child(1) {
    top: -10px;
    left: -10px;
  }
  
  &:nth-child(2) {
    top: -10px;
    right: -10px;
  }
  
  &:nth-child(3) {
    bottom: -10px;
    left: -10px;
  }
  
  svg {
    width: 24px;
    height: 24px;
    color: #9f70ff;

    @media (max-width: 768px) {
      width: 20px;
      height: 20px;
    }

    @media (max-width: 480px) {
      width: 18px;
      height: 18px;
    }
  }

  @media (max-width: 768px) {
    &:nth-child(1) {
      top: -8px;
      left: -8px;
    }
    
    &:nth-child(2) {
      top: -8px;
      right: -8px;
    }
    
    &:nth-child(3) {
      bottom: -8px;
      left: -8px;
    }
  }

  @media (max-width: 480px) {
    &:nth-child(1) {
      top: -6px;
      left: -6px;
    }
    
    &:nth-child(2) {
      top: -6px;
      right: -6px;
    }
    
    &:nth-child(3) {
      bottom: -6px;
      left: -6px;
    }
  }
`;
const IconWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #9f70ff 0%, #5d3fe8 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  animation: ${pulse} 3s infinite ease-in-out;
  
  svg {
    width: 60px;
    height: 60px;
    color: #ffffff;

    @media (max-width: 768px) {
      width: 50px;
      height: 50px;
    }
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: 800;
  text-align: center;
  margin-bottom: 1rem;
  background: linear-gradient(to right, #ffffff, #9f70ff, #ffffff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 0 2px 10px rgba(159, 112, 255, 0.3);

  @media (max-width: 768px) {
    font-size: 1.75rem;
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const Subtitle = styled.p`

  color: rgba(255, 255, 255, 0.8);
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 2rem;
  line-height: 1.6;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    margin-bottom: 1.5rem;
  }
`;

const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.0rem;
  margin: 2rem 0;
  text-align: center;
`;

const StatItem = styled.div`
  color: white;
  
.number {
    font-size: 1.2rem !important;
    font-weight: 700;
    background: linear-gradient(to right, #9f70ff, #5d3fe8);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin-bottom: 0.5rem;

    @media (min-width: 768px) {
      font-size: 2rem !important;
    }

    @media (min-width: 1024px) {
      font-size: 2.5rem !important;
    }
  }
   
  
  .label {
    color: rgba(255, 255, 255, 0.8);
    // font-size: 0.9rem;
  }
`;

const ViewDetails = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
  margin: 1rem 0;
  
  &:hover {
    color: #ffd700;
    transform: translateX(5px);
  }

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 0.75rem;
  }
`;

const Button = styled.button`
  background: ${props => props.primary ?
    'linear-gradient(135deg, #9f70ff 0%, #5d3fe8 100%)' :
    'rgba(159, 112, 255, 0.1)'};
  color: #ffffff;
  border: none;
  border-radius: 12px;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: auto;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(159, 112, 255, 0.3);
  }
  
  svg {
    width: 20px;
    height: 20px;
  }

  @media (max-width: 768px) {
    padding: 0.875rem 1.5rem;
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const Divider = styled.div`
  height: 1px;
  background: linear-gradient(to right, rgba(159, 112, 255, 0.1), rgba(159, 112, 255, 0.5), rgba(159, 112, 255, 0.1));
  margin: 1rem 0;

  @media (max-width: 768px) {
    margin: 1.5rem 0;
  }
`;

// Component remains the same
const ClubMembership = () => {
    const [count, setCount] = useState(50000);
    const [isJoinModalOpen, setIsJoinModalOpen] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const interval = setInterval(() => {
            setCount(prevCount => prevCount + 1);
        }, 2000);

        return () => clearInterval(interval);
    }, []);

    const formatNumber = (num) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(num);
    };

    const handleJoinClub = (clubCode) => {
        console.log('Joining club with code:', clubCode);
        setIsJoinModalOpen(false);
    };

    return (
        <Container>
            <TopOrb />
            <BottomOrb />
            <Card>
                <IconContainer>
                    <IconWrapper>
                        <Crown />
                    </IconWrapper>
                    <FloatingIcon>
                        <Trophy />
                    </FloatingIcon>
                    <FloatingIcon>
                        <Star />
                    </FloatingIcon>
                    <FloatingIcon>
                        <Award />
                    </FloatingIcon>
                </IconContainer>

                <Title>Run Your Own Casino Club</Title>
                <Subtitle>
                    Join club membership for exclusive benefits, including huge rakeback commissions and exciting rewards!
                </Subtitle>

                <StatsContainer>
                    <StatItem>
                        <div className="number">500+</div>
                        <div className="label">Active Clubs</div>
                    </StatItem>
                    <StatItem>
                        <div className="number">50K+</div>
                        <div className="label">Members</div>
                    </StatItem>
                    <StatItem>
                        <div className="number" style={{ position: 'relative' }}>
                            <span className='number'>{formatNumber(count)}</span>
                            <span
                            className='number'
                                style={{
                                    position: 'absolute',
                                    right: '0',
                                    animation: 'fadeInOut 2s infinite',
                                    opacity: 0,

                                }}
                            >
                                {`${String(count).slice(-1)}+`}
                            </span>
                        </div>
                        <div className="label">Rewards</div>
                    </StatItem>
                </StatsContainer>

                <Divider />

                <ViewDetails href="#">
                    View all details
                    <ChevronRight size={20} />
                </ViewDetails>

                <ButtonContainer>
                    <Button  onClick={() => history.push('/create-own-club')} primary>
                        Create Your Own Club
                    </Button>
                    <Button onClick={() => setIsJoinModalOpen(true)} primary>
                        Join a Club
                    </Button>
                </ButtonContainer>
            </Card>
            <JoinClubModal
                isOpen={isJoinModalOpen}
                onClose={() => setIsJoinModalOpen(false)}
                onJoin={handleJoinClub}
            />
        </Container>
    );
};

export default ClubMembership;