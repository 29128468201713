import styled from "styled-components"

const Text = styled`
font-size:24px;
color:white;

`

export default function BonusNew() {
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: "100%" }}>

            <div style={{ fontSize: "20px" }}>
                Bonus
            </div>
        </div>
    )
}