import React from "react";
import ReactTooltip from "react-tooltip";

export default class Social extends React.Component {
  render() {
    return (
      <div style={{width:'100%', display:"flex", justifyContent:"center", alignItems:"center"}}>
        <span className="btn-social-icon socials">
          <ReactTooltip />
          <a
            href="https://www.facebook.com/"
            target={"_blank"}
            data-tip={"FaceBook Page"}
          >
            <i
              className={"mdi mdi-facebook-box font-25"}
              style={{ color: "rgb(93 120 127)" }}
            />
          </a>
          <a
            href="https://x.com/Skyla_"
            target={"_blank"}
            data-tip={"Twitter News"}
          >
            <i
              className={"mdi mdi-twitter-box font-25"}
              style={{ color: "rgb(93 120 127)" }}
            />
          </a>
          <a
            href="https://www.instagram.com/Skyla_games/"
            target={"_blank"}
            data-tip={"Instagram Page"}
          >
            <i
              className={"mdi mdi-instagram font-25"}
              style={{ color: "rgb(93 120 127)" }}
            />
          </a>
          <a
            href="https://t.me/Skylagames"
            target={"_blank"}
            data-tip={"Telegram"}
          >
            <i
              className={"mdi mdi-telegram font-25"}
              style={{ color: "rgb(93 120 127)" }}
            />
          </a>
        </span>
      </div>
    );
  }
}
